var exports = {};

function objectAssignPolyfill() {
  "function" != typeof Object.assign && (Object.assign = function (e, t) {
    var n = arguments;
    if (null == e) throw new TypeError("Cannot convert undefined or null to object");

    for (var i = Object(e), o = 1; o < arguments.length; o++) {
      var r = n[o];
      if (null != r) for (var s in r) Object.prototype.hasOwnProperty.call(r, s) && (i[s] = r[s]);
    }

    return i;
  });
}

function objectKeysPolyfill() {
  var e, t, n, i;
  Object.keys || (Object.keys = (e = Object.prototype.hasOwnProperty, t = !{
    toString: null
  }.propertyIsEnumerable("toString"), i = (n = ["toString", "toLocaleString", "valueOf", "hasOwnProperty", "isPrototypeOf", "propertyIsEnumerable", "constructor"]).length, function (o) {
    if ("function" != typeof o && ("object" != typeof o || null === o)) throw new TypeError("Object.keys called on non-object");
    var r,
        s,
        a = [];

    for (r in o) e.call(o, r) && a.push(r);

    if (t) for (s = 0; s < i; s++) e.call(o, n[s]) && a.push(n[s]);
    return a;
  }));
}

function __rest(e, t) {
  var n = {};

  for (var i in e) Object.prototype.hasOwnProperty.call(e, i) && t.indexOf(i) < 0 && (n[i] = e[i]);

  if (null != e && "function" == typeof Object.getOwnPropertySymbols) {
    var o = 0;

    for (i = Object.getOwnPropertySymbols(e); o < i.length; o++) t.indexOf(i[o]) < 0 && Object.prototype.propertyIsEnumerable.call(e, i[o]) && (n[i[o]] = e[i[o]]);
  }

  return n;
}

Object.defineProperty(exports, "__esModule", {
  value: !0
});

var supportsCookies = function () {
  try {
    return Boolean(navigator.cookieEnabled);
  } catch (e) {
    return !1;
  }
},
    supportsSendBeacon = function () {
  try {
    return Boolean(navigator.sendBeacon);
  } catch (e) {
    return !1;
  }
},
    supportsXMLHttpRequest = function () {
  try {
    return Boolean(XMLHttpRequest);
  } catch (e) {
    return !1;
  }
},
    isUndefined = function (e) {
  return void 0 === e;
},
    isString = function (e) {
  return "string" == typeof e;
},
    isNumber = function (e) {
  return "number" == typeof e;
},
    isFunction = function (e) {
  return "function" == typeof e;
};

function makeSendEvents(e) {
  return function (t) {
    var n = this;

    if (!this._userHasOptedOut) {
      if (!this._hasCredentials) throw new Error("Before calling any methods on the analytics, you first need to call the 'init' function with appId and apiKey parameters");
      var i = t.map(function (e) {
        var t,
            i,
            o = e.filters,
            r = __rest(e, ["filters"]),
            s = Object.assign(Object.assign({}, r), {
          userToken: (i = null === (t = e) || void 0 === t ? void 0 : t.userToken, null !== i && void 0 !== i ? i : n._userToken)
        });

        return isUndefined(o) || (s.filters = o.map(encodeURIComponent)), s;
      });
      return sendRequest(e, this._appId, this._apiKey, this._ua, this._endpointOrigin, i);
    }
  };
}

function sendRequest(e, t, n, i, o, r) {
  return e(o + "/1/events?X-Algolia-Application-Id=" + t + "&X-Algolia-API-Key=" + n + "&X-Algolia-Agent=" + encodeURIComponent(i.join("; ")), {
    events: r
  });
}

var version = "2.2.1",
    DEFAULT_ALGOLIA_AGENTS = ["insights-js (" + version + ")", "insights-js-browser-cjs (" + version + ")"];

function addAlgoliaAgent(e) {
  -1 === this._ua.indexOf(e) && this._ua.push(e);
}

var SUPPORTED_REGIONS = ["de", "us"],
    MONTH = 2592000000;

function init(e) {
  var t;
  if (!e) throw new Error("Init function should be called with an object argument containing your apiKey and appId");
  if (isUndefined(e.apiKey) || !isString(e.apiKey)) throw new Error("apiKey is missing, please provide it so we can authenticate the application");
  if (isUndefined(e.appId) || !isString(e.appId)) throw new Error("appId is missing, please provide it, so we can properly attribute data to your application");
  if (!isUndefined(e.region) && -1 === SUPPORTED_REGIONS.indexOf(e.region)) throw new Error("optional region is incorrect, please provide either one of: " + SUPPORTED_REGIONS.join(", ") + ".");
  if (!(isUndefined(e.cookieDuration) || isNumber(e.cookieDuration) && isFinite(e.cookieDuration) && Math.floor(e.cookieDuration) === e.cookieDuration)) throw new Error("optional cookieDuration is incorrect, expected an integer.");
  false, this._apiKey = e.apiKey, this._appId = e.appId, this._userHasOptedOut = !!e.userHasOptedOut, this._region = e.region, this._endpointOrigin = e.region ? "https://insights." + e.region + ".algolia.io" : "https://insights.algolia.io", this._useCookie = null !== (t = e.useCookie) && void 0 !== t && t, this._cookieDuration = e.cookieDuration ? e.cookieDuration : 6 * MONTH, this._hasCredentials = !0, this._ua = [].concat(DEFAULT_ALGOLIA_AGENTS), e.userToken ? this.setUserToken(e.userToken) : this._userToken || this._userHasOptedOut || !this._useCookie || this.setAnonymousUserToken();
}

function getVersion(e) {
  isFunction(e) && e(this.version);
}

function addEventType(e, t) {
  return t.map(function (t) {
    return Object.assign({
      eventType: e
    }, t);
  });
}

function clickedObjectIDsAfterSearch() {
  for (var e = [], t = arguments.length; t--;) e[t] = arguments[t];

  return this.sendEvents(addEventType("click", e));
}

function clickedObjectIDs() {
  for (var e = [], t = arguments.length; t--;) e[t] = arguments[t];

  return this.sendEvents(addEventType("click", e));
}

function clickedFilters() {
  for (var e = [], t = arguments.length; t--;) e[t] = arguments[t];

  return this.sendEvents(addEventType("click", e));
}

function convertedObjectIDsAfterSearch() {
  for (var e = [], t = arguments.length; t--;) e[t] = arguments[t];

  return this.sendEvents(addEventType("conversion", e));
}

function convertedObjectIDs() {
  for (var e = [], t = arguments.length; t--;) e[t] = arguments[t];

  return this.sendEvents(addEventType("conversion", e));
}

function convertedFilters() {
  for (var e = [], t = arguments.length; t--;) e[t] = arguments[t];

  return this.sendEvents(addEventType("conversion", e));
}

function viewedObjectIDs() {
  for (var e = [], t = arguments.length; t--;) e[t] = arguments[t];

  return this.sendEvents(addEventType("view", e));
}

function viewedFilters() {
  for (var e = [], t = arguments.length; t--;) e[t] = arguments[t];

  return this.sendEvents(addEventType("view", e));
}

var createUUID = function () {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (e) {
    var t = 16 * Math.random() | 0;
    return ("x" === e ? t : 3 & t | 8).toString(16);
  });
},
    COOKIE_KEY = "_ALGOLIA",
    setCookie = function (e, t, n) {
  var i = new Date();
  i.setTime(i.getTime() + n);
  var o = "expires=" + i.toUTCString();
  document.cookie = e + "=" + t + ";" + o + ";path=/";
},
    getCookie = function (e) {
  for (var t = e + "=", n = document.cookie.split(";"), i = 0; i < n.length; i++) {
    for (var o = n[i]; " " === o.charAt(0);) o = o.substring(1);

    if (0 === o.indexOf(t)) return o.substring(t.length, o.length);
  }

  return "";
};

function setAnonymousUserToken() {
  if (supportsCookies()) {
    var e = getCookie(COOKIE_KEY);
    e && "" !== e && 0 === e.indexOf("anonymous-") ? this.setUserToken(e) : (this.setUserToken("anonymous-" + createUUID()), setCookie(COOKIE_KEY, this._userToken, this._cookieDuration));
  }
}

function setUserToken(e) {
  this._userToken = e, isFunction(this._onUserTokenChangeCallback) && this._onUserTokenChangeCallback(this._userToken);
}

function getUserToken(e, t) {
  return isFunction(t) && t(null, this._userToken), this._userToken;
}

function onUserTokenChange(e, t) {
  this._onUserTokenChangeCallback = e, t && t.immediate && isFunction(this._onUserTokenChangeCallback) && this._onUserTokenChangeCallback(this._userToken);
}

objectKeysPolyfill(), objectAssignPolyfill();

var AlgoliaAnalytics = function (e) {
  var t = e.requestFn;
  this._ua = [], this.version = version, this._hasCredentials = !1, this.sendEvents = makeSendEvents(t).bind(this), this.init = init.bind(this), this.addAlgoliaAgent = addAlgoliaAgent.bind(this), this.setUserToken = setUserToken.bind(this), this.setAnonymousUserToken = setAnonymousUserToken.bind(this), this.getUserToken = getUserToken.bind(this), this.onUserTokenChange = onUserTokenChange.bind(this), this.clickedObjectIDsAfterSearch = clickedObjectIDsAfterSearch.bind(this), this.clickedObjectIDs = clickedObjectIDs.bind(this), this.clickedFilters = clickedFilters.bind(this), this.convertedObjectIDsAfterSearch = convertedObjectIDsAfterSearch.bind(this), this.convertedObjectIDs = convertedObjectIDs.bind(this), this.convertedFilters = convertedFilters.bind(this), this.viewedObjectIDs = viewedObjectIDs.bind(this), this.viewedFilters = viewedFilters.bind(this), this.getVersion = getVersion.bind(this);
};

function getFunctionalInterface(e) {
  return function (t) {
    for (var n = [], i = arguments.length - 1; i-- > 0;) n[i] = arguments[i + 1];

    t && isFunction(e[t]) ? e[t].apply(e, n) : console.warn("The method `" + t + "` doesn't exist.");
  };
}

var requestWithSendBeacon = function (e, t) {
  var n = JSON.stringify(t);
  if (!navigator.sendBeacon(e, n)) return requestWithXMLHttpRequest(e, t);
},
    requestWithXMLHttpRequest = function (e, t) {
  var n = JSON.stringify(t),
      i = new XMLHttpRequest();
  i.open("POST", e), i.send(n);
};

function getRequesterForBrowser() {
  if (supportsSendBeacon()) return requestWithSendBeacon;
  if (supportsXMLHttpRequest()) return requestWithXMLHttpRequest;
  throw new Error("Could not find a supported HTTP request client in this environment.");
}

function processQueue(e) {
  var t = e.AlgoliaAnalyticsObject;

  if (t) {
    var n = getFunctionalInterface(this),
        i = e[t];
    i.queue = i.queue || [];
    var o = i.queue;
    o.forEach(function (e) {
      var t = [].slice.call(e),
          i = t[0],
          o = t.slice(1);
      n.apply(void 0, [i].concat(o));
    }), o.push = function (e) {
      var t = [].slice.call(e),
          i = t[0],
          o = t.slice(1);
      n.apply(void 0, [i].concat(o));
    };
  }
}

function createInsightsClient(e) {
  return getFunctionalInterface(new AlgoliaAnalytics({
    requestFn: e
  }));
}

var entryBrowserCjs = createInsightsClient(getRequesterForBrowser());
exports.AlgoliaAnalytics = AlgoliaAnalytics, exports.default = entryBrowserCjs, exports.getFunctionalInterface = getFunctionalInterface, exports.getRequesterForBrowser = getRequesterForBrowser, exports.processQueue = processQueue;
export default exports;